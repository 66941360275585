@import "../../sass/mixins";
@import "../../sass/colors";

.Contact {
  margin: 10px 5% 10% 5%;

  &Terms {
    text-align: left;
    padding: 2%;
    position: relative;

    &Body {
      font-style: italic;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .4;
      z-index: -1;
      background: $red;
      border-radius: 10px;
    }

    @include device(mobile) {
      padding: 5%;
    }
  }

  &Click {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
