@import "../../../sass/mixins";

.Cards {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1rem;
  grid-auto-flow: row;

  &.check {
    grid-template-columns: repeat(5, 1fr);;
    padding: 10px;
  }

  &.reduceMotion {
    .Card {
      transform: none;
    }
  }

  @include device(tablet) {
    grid-template-columns: repeat(5, 1fr);;
    grid-gap: 10px;

    &.check {
      grid-template-columns: repeat(3, 1fr);;
    }
  }

  @include device(mobile) {
    grid-template-columns: repeat(3, 1fr);;
    grid-gap: 10px;
  }
}
