@import "../../../sass/mixins";
@import "../../../sass/colors";

.Modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0.5, 0.5, 0.5, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
}
