@import "../../../sass/mixins";
.SetForm {
  position: fixed;
  width: 50%;
  z-index: 1;
  display: grid;
  grid-gap: 10px;
  background: white;
  padding: 10px;
  border-radius: 1em;

  .Cards {
    height: 50vh;
    overflow: scroll;

    //.Blocker{
    //  z-index: -1;
    //}
  }

  &Count {
    align-self: end;
    color: grey;
    font-size: 24px;
    padding: 5px;
  }

  &Group1 {
    display: grid;
    grid-template-columns: 50fr 40fr 10fr;
    grid-gap: 10px;
  }

  &Group3 {
    display: contents;
  }

  &Group2 {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;

    .Button {
      width: 100%;
    }
  }

  @include device(tablet) {
    &Group1 {
      display: contents;
    }
    &Group3 {
      display: grid;
      grid-template-columns: 80fr 20fr;
      grid-gap: 10px;
    }
  }

  @include device(mobile) {
    width: 90%;

    .Cards {
      height: 40vh;
    }
    &Group2 {
      display: contents;
    }
  }
}
