@import "../../../sass/mixins";

.Search {
  display: grid;
  grid-template-columns: 100fr auto;
  height: inherit;
  overflow: hidden;


  .Type {
    padding: 0;
    &Box {
      border: none;
      background: #111;
      color: white;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .ButtonIcon {
    height: inherit;
    width: inherit;
    box-sizing: border-box;
    aspect-ratio: 1/1;
    margin: 0;
    border: none;
    background: #5e5e5e;
    &:hover {
      background: #9e9e9e;
    }
  }
}
