@import "../../../sass/mixins";

.Classifier {
  .Type{
    &BoxWrap {
      display: grid;
      grid-template-columns: 100fr auto;
      border: 1px solid rgba(0,0,0,0.5);
      border-radius: 5px;
    }
    &Box{
      border: none;
    }
  }
  &Return {
    &.ButtonIcon.Button {
      height: 34px;
      padding: 4px;
      border: none;
    }
    &:hover {
      background: rgba(0,0,0,0.1);
      background-origin: padding-box;
    }
  }

  @include device(mobile) {
    display: contents;
  }
}
