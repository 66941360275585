@import "../../../sass/mixins";

.Type {
  overflow: hidden;
  &Label {
    text-align: left;
    text-transform: uppercase;
    font-size: 12px;
    color: grey;
  }
  &Box {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    font-size: 20px;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 5px;

    &Wrap {
      height: inherit;
      display: flex;
    }
    &:focus-visible {
      outline: none;
    }
  }

  select {
    cursor: pointer;
  }

  @include device(mobile) {
    select {
      appearance: none;
      background: transparent;
      color: black;
    }
  }

}