@import "../../../sass/mixins";

.ActionBar {
  display: flex;
  justify-content: flex-end;

  .Sort {
    height: 34px;
    width: 21vw;
    box-sizing: border-box;
  }
  @include device(tablet) {
    .Sort {
      height: 40px;
      width: 35vw;
    }
  }
  @include device(mobile) {
    display: inherit;

    .Sort {
      height: 40px;
      width: 100%;
    }
  }
}
