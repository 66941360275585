//  map holding breakpoint values
$breakpoints: (
  mobile: 480px,
  tablet: 768px,
);

//  mixin to print out media queries (based on map keys passed)
@mixin device($keys...) {
  @each $key in $keys {
    @media (max-width: map-get($breakpoints, $key)) {
      @content;
    }
  }
}
