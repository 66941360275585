@import "../../../sass/mixins";
@import "../../../sass/colors";

.Section {
  margin: 20px 0 100px 0;

  &Header {
    border-bottom: 1px solid blue;
    margin: 0 5%;
    display: grid;
    grid-template-columns: auto max-content;
    box-sizing: border-box;
  }

  &Share {
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2px;

    .ButtonIcon.Button {
      margin: 0;
      height: 100%;
      border: none;
      padding: 5px;
    }
  }

  &Name {
    font-size: 24px;
    text-transform: capitalize;
    text-align: left;
  }

  &Price {
    font-size: 16px;

    &Total {
      font-size: 24px;
      color: grey;
      display: inline-block;

      &Slash {
        display: block;
        position: relative;
        height: 0;
        width: 100%;
        border-top: 2px solid $red;
        transform: rotate(-16deg);
        top: 16px;
      }
    }

    &Value {
      font-size: 24px;
      padding-left: 10px;
    }

    &Discount {
      color: grey;
      padding-left: 10px;
    }
  }

  @include device(tablet) {
    margin-bottom: 40px;
  }
  @include device(mobile) {
  }
}
