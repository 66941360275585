@import "../../../sass/mixins";
@import "../../../sass/colors";

.Header {
  box-sizing: border-box;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;

  &Wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &Title {
    margin: 20px;
  }
  &Name {
    font-size: 32px;
    font-family: monospace;
    text-align: center;
  }
  &Caption {
    font-size: 16px;
    font-family: Baskerville, sans-serif;
    word-spacing: 5px;
  }

  &Icon {
    width: 40px;
    margin: 20px;
    padding: 2px;
    cursor: pointer;
    align-self: center;
    img {
      width: 100%;
    }
    &:hover {
      width: 44px;
      padding: 0;
    }
    &.Toggle {
      opacity: 0;
      transition: 0.3s;
    }
  }
  &Blocker {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    background: rgba(0, 0, 0, 0);
  }
  &Slide {
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    transition: 0.5s;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    opacity: 0;

    &.Nav {
      height: 100%; /* 100% Full-height */
      left: 0;
      padding-top: 60px; /* Place content 60px from the top */

      &.Open {
        width: 30%;
        opacity: 1;
        & a, & button {
          opacity: 1;
        }
      }
    }

    &.SearchBar {
      top: 74px;
      right: 0;
      height: 32px;
      padding: 20px;

      &.Open {
        width: 30%;
        opacity: 1;
        border-radius: 5px;
      }
    }

    .NavLink {
      opacity: 0;
      padding: 8px 0;
      text-decoration: none;
      font-size: 25px;
      color: #818181;
      display: block;
      transition: 0.3s;

      &:hover {
        color: #f1f1f1;
      }

      &.Close, &.Add, &.Total {
        background: transparent;
        border: none;
      }

      &.Add, &.Total {
        width: 100%;
      }

      &.Close {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
      }

      &.Add {
        margin-top: 20px;
      }
    }
  }

  @include device(tablet) {
    &Icon {
      margin: 10px;
    }
    &Slide {
      &.Nav {
        &.Open {
          width: 40%;
        }
      }
      &.SearchBar {
        top: 95px;
        padding: 10px;
        &.Open {
          width: 40%;
        }
      }
    }
  }

  @include device(mobile) {
    &Icon {
      margin: 5px;
    }
    &Title {
      margin: 10px;
    }
    &Name {
      font-size: 24px;
    }
    &Caption {
      font-size: 14px;
    }

    &Slide {
      &.Nav {
        &.Open {
          width: 70%;
        }
      }
      &.SearchBar {
        top: 65px;
        padding: 10px;
        &.Open {
          display: table;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
  }
}
