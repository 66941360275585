@import "../../../sass/mixins";
@import "../../../sass/colors";

.ItemForm {
  position: fixed;
  background: white;
  padding: 20px;
  border-radius: 1em;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.6);
  display: grid;
  width: 30%;
  z-index: 1;
  gap: 10px;

  &Id {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }

  &Row1 {
    display: grid;
    grid-template-columns: auto auto min-content;
    grid-gap: 10px;
  }

  &Row2 {
    display: grid;
    grid-template-columns: auto auto;
    align-items: end;
    grid-gap: 10px;
  }

  &Row3 {
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 10px;
  }

  &Bulk.Type {
    display: flex;
    flex-direction: column;

    &BoxWrap {
      height: 100%;
    }
  }

  &Confirm {
    margin-top: 20px;
  }

  @include device(tablet) {
    width: 50%;
  }

  @include device(mobile) {
    padding: 20px;
    border-radius: 1em;
    box-sizing: border-box;
    width: 90%;
  }
}
