@import "../../../sass/mixins";
@import "../../../sass/colors";

.Card {
  position: relative;
  height: 100%;
  z-index: 0;

  //&:hover.desktop:not(.check) {
  //  & .Card {
  //    &Wrapper {
  //      @include device(tablet) {
  //        transform: scale(1.5);
  //        padding: 10px;
  //        position: fixed;
  //        top: 35%;
  //        left: 35%;
  //        width: 30%;
  //      }
  //      @include device(mobile) {
  //        transform: scale(1.5);
  //        padding: 5px;
  //        position: fixed;
  //        top: 35%;
  //        left: 30%;
  //        width: 40%;
  //      }
  //    }
  //  }
  //}
  &Wrapper {
    padding: 10px;
    border-radius: 1em;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
    height: 100%;
    box-sizing: border-box;
    background: white;
  }

  &Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: min-content;
  }

  .Hidden {
    display: none;
    opacity: 0;
    text-align: left;
  }

  &Quantity {
    font-size: 12px;
  }

  &Update {
    width: 12px;
    padding: 2px;
    img {
      width: 100%;
      height: 100%;
      &:hover {
        @media (prefers-reduced-motion: no-preference) {
          animation: spin infinite 4s linear;
        }
      }
    }
    &:hover {
      width: 16px;
      padding: 0;
    }
  }

  &Image {
    border-radius: 1em;
    overflow: hidden;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Name {
    font-size: 16px;
    word-break: break-word;
    text-align: center;
  }

  &Content {
    text-align: left;
  }

  &Price {
    padding-top: 2px;
    font-size: 18px;
  }
  &Category {
    font-size: 6px;
    color: grey;
    text-transform: uppercase;
  }
  &Description {
    font-size: 6px;
    padding-top: 6px;
    font-style: italic;
  }

  .Type {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 28px;
    height: 28px;
  }

  @include device(tablet) {
    &Wrapper {
      padding: 3%;
    }
  }

  @include device(mobile) {
    &Wrapper {
      padding: 2%;
      border-radius: 0.5em;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    }
    &Update {
      width: 14px;
      &:hover,
      &:focus {
        width: 20px;
      }
    }

    &Image {
      margin-top: 5px;
      border-radius: 0.5rem;
    }

    &Name {
      padding-top: 5px;
      font-size: 12px;
    }

    &Category {
      font-size: 8px;
    }

    &Price {
      font-size: 16px;
    }
    &Description {
      padding-top: 5px;
      font-size: 10px;
    }
  }
}
