@import "../../../sass/mixins";

.Button {
  padding: 5px;
  font-size: 20px;
  color: grey;
  text-transform: uppercase;
  border: 1px solid rgba(0,0,0,0.5);
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(0,0,0,0.1);
  }

  &:focus-visible {
    outline: none;
  }

  &Box {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    font-size: 20px;
  }

  @include device(mobile) {
    width: 100%;
    box-sizing: border-box;
    background: rgba(0,0,0,0.1);
    border-radius: 2em;
  }
}
