@import "../../../sass/mixins";
.ButtonIcon {
  border-radius: 5px;
  &.Button {
    padding: 0;
  }
  img,svg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  @include device(mobile) {
    width: auto;
    height: 100%;
    aspect-ratio: 1/1;
  }
}
