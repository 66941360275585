@import "../../../sass/mixins";

.Sort {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;

  .Type {
    width: 100%;
    padding: 0;
    position: relative;

    &Box {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      &:focus-visible {
        outline: none;
      }
      background: transparent;
      &Wrap {
        width: 100%;
      }
    }
  }

  &Sort,
  &Order {
    border: none;
    margin: 0;
  }
  &Sort {
    height: 100%;
    width: min-content;
    position: absolute;
    right: 0;
    z-index: -1;
    background: transparent;
  }
  &Order {
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &.Up {
      path.Up {
        fill: rgba(0,0,0,0.8);
      }
    }
    &.Down {
      path.Down {
        fill: rgba(0,0,0,0.8);
      }
    }
  }
}
