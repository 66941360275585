@import "../../sass/mixins";

.Set {
  margin: 10px 0;
  position: relative;
  z-index: 0;

  .Cards {
    margin-top: 20px;
  }

  @include device(tablet) {
  }

  @include device(mobile) {
  }
}
